<template>
  <div class="event-detail">
    <a-row class="title">
      {{ state.event.title }}
    </a-row>
    <a-row :gutter="25">
      <a-col :xxl="8" :lg="8" :md="12" :xs="24">
        <div class="event--card1">
          <div class="event_image">
            <img
              :src="state.event.front_page ? base_url + state.event.front_page : 'https://via.placeholder.com/448x248'"
              alt="event_img"
            />
          </div>
          <div class="event__description">
            <p class="subtitle">Descripción:</p>
            <p class="content" v-if="state.event.description">
              {{ state.event?.description ? state.event.description : '-' }}
            </p>
          </div>
        </div>
      </a-col>

      <a-col :xxl="16" :lg="16" :md="12" :xs="24">
        <a-row class="event--card">
          <a-col :xxl="24" :lg="24" :xs="24">
            <a-row class="mb-3">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Área que creó el evento</p>
                <p class="content">{{ state?.event?.creator_area || '-' }}</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha de inicio del evento</p>
                <p class="content">{{ formatDate(state.event.event_date) }}, {{removeSeconds( state.event.event_hour) }}hrs.</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24" v-if="state.event.type === 'normal'">
                <p class="subtitle">Fecha de finalización del evento</p>
                <p class="content">
                  {{
                    state.event.end_date
                      ? `${formatDate(state.event.end_date)}, ${removeSeconds(state.event.end_time)} hrs.`
                      : 'No definida'
                  }}
                </p>
              </a-col>
            </a-row>
            <a-row class="mb-3" v-if="state.event.type === 'other'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha de creación del evento</p>
                <p class="content">{{ formatDate(state.event.date_event_create) || '-' }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3" v-if="state.event.type !== 'other'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha del inicio de registro</p>
                <p class="content">{{ formatDate(state.event.start_register_date) || '-' }}</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha de finalización de registro</p>
                <p class="content">{{ formatDate(state.event.end_register_date) || '-' }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3" v-if="state.event.type !== 'other'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Sede(s)</p>
                <template v-if='state.event.headquarters.length'>
                  <div v-for="sede in state.event.headquarters" :key="sede.id">
                  <span>
                    <p class="content">{{ sede.name }}</p>
                    <p class="content">
                      {{
                        `${sede.street}, ${sede.suburb ? sede.suburb+',': ''} ${sede.postal_code ? sede.postal_code :''} ${sede.municipality ? sede.municipality+',' : ''} ${sede?.references ? sede.references : ''} `
                      }}
                    </p>
                  </span>
                  </div>
                </template>
                <template v-else>
                  <div >
                  <span>
                    <p class="content">-</p>
                    <p class="content">-</p>
                  </span>
                  </div>
                </template>

              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Archivos de la convocatoria</p>
                <div class="files-content">
                  <a-row class="files" v-for="file in JSON.parse(state.event.files)" :key="file">
                    <a-col :xxl="2" :lg="2" :xs="2">
                      <img
                        class="files-icon"
                        :src="require('@/static/img_events/SEJ enventos_adjuntos.svg')"
                        alt="Aricho adjunto"
                      />
                    </a-col>
                    <a-col :xxl="20" :lg="20" :xs="20" @click="handleDownloadFile(file.route, file.name)">
                      {{ file.name }}
                    </a-col>
                  </a-row>
                </div>
              </a-col>
            </a-row>
            <a-row class="mb-3" v-if="state.event.type === 'normal'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">¿A quién va dirigido el evento?</p>
                <div class="content">
                  {{ aimedAt() }}
                  <div v-if="state.event.all_areas">
                    <span>
                      - Todas las áreas administrativas pueden participar
                    </span>
                  </div>
                  <div v-if="state.event.all_work_areas">
                    <span>
                      - Todos los centros de trabajo pueden participar
                    </span>
                  </div>
                  <div v-if="state.administrativeArea.length > 0">
                    <span v-for="(area, index) in state.administrativeArea" :key="area.id_arbol">
                      {{ (index+1) + '.-' + area.nombre }} <br/>
                    </span>
                  </div>
                  <div v-if="state.workCenter.length > 0">
                    <span v-for="(workCenter, index) in state.workCenter" :key="workCenter.id">
                      {{ (index+1) + '.-' + workCenter.nombre.charAt(0) + workCenter.nombre.slice(1).toLowerCase() }} <br/>
                    </span>
                  </div>
                </div>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">¿El evento tendrá actividades?</p>
                <p class="content">{{ state.event.activities ? 'Sí' : 'No' }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3" v-if="state.event.type === 'normal'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">¿El evento general tendrá varios eventos agrupados?</p>
                <p class="content">{{state.event.grouped_events ? 'Si' : 'No'}}</p>
              </a-col>

            </a-row>
          </a-col>
        </a-row>
        <a-row class="event--card event-info" v-if="state.event.info_visible && state.event.type === 'normal'">
          <a-col :xxl="24" :lg="24" :xs="24">
            <a-row class="mb-3">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Responsable del evento</p>
                <p class="content">{{ state.event.responsible?.name }}</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Correo del responsable del evento</p>
                <p class="content">{{ state.event.responsible?.email }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Teléfono del responsable del evento</p>
                <p class="content">{{ state.event?.telephone }}</p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <sdCards class="event--card" v-if="state.event.grouped_events && state.event.subevents.length">
          <a-row class="title" v-if='canRegister()'>
            Listado de eventos para registro individual
          </a-row>
          <p class="content"
             v-if='canRegister()'>
            Da click en el checkbox al costado del evento en el que desees registrarte, si hay dos o más eventos en el
            mismo horario sólo podrás registrarte a uno de ellos.
          </p>
          <div class="subevent-row" v-for="(subevent, index) in state.event.subevents" :key="`sub-${subevent.id}`">
            <a-form-item v-if='canRegister()' ref="educativos" :name="`subevent-${index}`" class="form-font mx-3 pr-4">
              <a-checkbox class="check-event register-checkbox" v-on:change="handleEnabledEvents($event, subevent)" :checked="subevent.checked" :disabled="subevent.disabledField"> </a-checkbox>
            </a-form-item>

            <sdCards class="card subevent--card">
              <sub-event
                :hide-link='true'
                :showRegisterParticipant='registerPartByCCT'
                :showRegisterAssistant='registerAssisByCCT'
                :parentID='state.event.id'
                :dynamicFormEvent="getDinamicForm(subevent.dynamicform)"
                :event="subevent"
                :indexSubevent="index"
                :getValidation="state.getValidation"
                v-on:validateForm="validateDinamicFormBySubevent"
              />
            </sdCards>
          </div>
        </sdCards>
      </a-col>
      <div
        class="event-detail__buttons"
        v-if="canRegister()"
      >

        <button @click='handleSubmit' class="btn-register">Registrarme</button>

      </div>
    </a-row>
  </div>
</template>

<script>
import { defineAsyncComponent, reactive } from 'vue';
import { getDetailsEvent, getFileDetail } from '@/services/events';
import { formatDate, removeAccents } from '@/utility/utility';
import { useRoute, useRouter } from 'vue-router';
import { getItem, setItem } from '@/utility/localStorageControl';
import { Notification } from 'ant-design-vue';
import {removeSeconds} from '@/utility/utility';
import { useStore } from 'vuex';

const SubEvent = defineAsyncComponent(() => import('./overview/SubEvents.vue'));

export default {
  name: 'EventDetail',
  components: { SubEvent },
  data() {
    return {
      registerPartByCCT: useRoute().query.registerpartbycct || 0,
      registerAssisByCCT: useRoute().query.registerassabycct || 0,
      singleRegister: useRoute().query.singleRegister || 0,
    }
  },
  methods: {

    canRegister(){
      return ((!this.registerAssisByCCT && !this.registerPartByCCT) &&
          this.state.event?.subevents?.length ||
      this.singleRegister &&
      this.dateIsAvailableToRegister(this.state.event) &&
      !this.state.event.already_registered && this.state.event.type === 'normal' &&
      (!this.state.event.director_register || this.userRoles[0].nombre === 'Director de CCT') &&
      (!this.state.event.only_admin_register || removeAccents(this.userRoles[0].nombre).toLowerCase() === 'administrador de area')) &&
        (this.state.event.amount_participants === null || (this.state.event.count_participants!=null && (this.state.event.count_participants < (this.state.event.amount_participants))))
    },
    handleSubmit(){
      if(this.state.event.grouped_events){
        //Llama el  metodo validateSection de cada subevent.
        //traite el campo answer:{} de los subevents
        //
        this.state.subeventsToRegister = this.state.event.subevents.filter((event) => {
          return event.checked
        })

        if(!this.state.subeventsToRegister.length){
          Notification['error']({
            message: 'Error',
            description: 'Debe de tener al menos un subevento seleccionado',
          });
        } else {
          //this.state.showNotificationEmptyDinamicForm = true;
          this.state.getValidation = !this.state.getValidation;
        }

      }else{
        setItem('subevents', null)
        this.router.push({path: `/home/register/participant/${this.state.event.id}`, params: { subevents: null }});
      }
    },
    validateDinamicFormBySubevent(response) {
      let hasError = undefined;
      this.state.subeventsToRegister.map((element) => {
        if(element.id == response.eventID){
          element.answers = response.answers;
          element.isValidDinamicForm = response.isValid;
        }
      })
      hasError = this.state.subeventsToRegister.find((element) => {
        return (element.isValidDinamicForm === false && element.activities == 1)
      })
      if(hasError == undefined){
        setItem('subevents', this.state.subeventsToRegister)
        if(this.singleRegister){
          this.router.push({path: `/home/register/assistant/${this.state.event.id}`, params: { subevents: null }});
        } else {
          this.router.push({ path: `/home/register/participant/${this.state.event.id}`, params: { subevents: this.state.subeventsToRegister }});
        }
      }
    }
  },
  async setup() {
    const store = useStore();
    const router = useRouter();
    const base_url = process.env.VUE_APP_BASE_URL;


    //const eventsHandleTime = reactive([]);

    const getEventDetail = async () => {
      let event = {};
      //console.log("aqui", useRoute().query.registerpartbycct)
      const showAllSubs = useRoute().query.registerpartbycct || useRoute().query.registerassabycct
      await getDetailsEvent(useRoute().params.id, showAllSubs )
      .then(response => {
        if (response.success) {
          if(!Array.isArray(response.event.subevents)){
            response.event.subevents= Object.values(response.event.subevents)
          }
          response.event.headquarters = response.event.headquarters.filter(sede=>sede.type!='virtual')
          event = response.event;

        }
      })
      .catch(() => {
        //console.log(e);
      });
      setActiveValuesInSubevents(event)
      return event;
    };

    const setActiveValuesInSubevents = (event) => {
      if(event){
        if(event.grouped_events && event.subevents.length){
          event.subevents.map((element) => {
            element['checked'] = false;
            element['disabledField'] = false;
            element['isValidDinamicForm'] = false;
            element['answers'] = null;
          })
        }
      }
    }

    const state = reactive({
      event: await getEventDetail(),
      workCenter: [],
      administrativeArea: [],
      getValidation: false,
      subeventsToRegister: [],
      showNotificationEmptyDinamicForm: true
    });

    const getDinamicForm = (form) => {
      return form != null ? JSON.parse(form.questions_json) : null;
    }

    const handleEnabledEvents = (input, event) => {
      event.checked = input.target.checked;
      state.event.subevents.map((element) => {
        if(element.id != event.id){
          if(!dateIsAvailableToRegisterInSubevent(element, event)) {
            if(input.target.checked){
              if(!element.checked && !element.disabledField) {
                element.checked = false;
                element.disabledField = true;
              }
            } else {
              element.checked = false;
              element.disabledField = false;
            }
          }
        }
      })
    }

    const aimedAt = () => {
      const educational = state.event.edu_can_register ? 'Estructura educativa' : '';
      const administrative = state.event.admin_can_register ? 'Personal en centro de trabajo administrativo' : '';
      const student = state.event.student_can_register ? 'Estudiantes' : '';
      const citizen = state.event.citizen_can_register ? 'Ciudadanos' : '';
      // const aimedAt = educational + ' ' + administrative + ' ' + student + ' ' + citizen;
      const aimedAt = [educational, administrative, student, citizen];
      return aimedAt.filter(val => !!val).join(' y ');
      // return aimedAt.trim().replace(/\s+/g, ' y ');
    };

    if (state.event.work_center_ids.length > 0 && !state.event.all_areas) {
      const getNameWorkCenter = async () => {
        let workCenters = store._state.data.userData.cct;
        let workCenterName = [];
        if(workCenters.length){
          JSON.parse(state.event.work_center_ids).map(workCenter => {
            workCenterName.push(workCenters.find(item => item.id == workCenter));
          });
        }
        return JSON.parse(JSON.stringify(workCenterName));
      };
      state.workCenter = await getNameWorkCenter();
    }

    if (state.event.work_center_ids.length > 0 && !state.event.all_work_areas) {
      const getNameAdministrativeAreas = async () => {
        let administrativeAreas = store._state.data.userData.administrative_areas;
        let administrativeAreaName = [];
        if(administrativeAreas.length){
          JSON.parse(state.event.administrative_area_ids).map(area => {
            administrativeAreaName.push(administrativeAreas.find(item => item.id_arbol == area));
          });
        }
        return JSON.parse(JSON.stringify(administrativeAreaName));
      };
      state.administrativeArea = await getNameAdministrativeAreas();
    }

    const handleDownloadFile = async (route, nameFile) => {
      getFileDetail(route)
        .then(response => {
          if (response && response.success !== false) {
            //Si es blob no tiene propiedad success, si falla success es false
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nameFile);
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }
        })
        .catch(() => {});
    };

    const dateIsAvailableToRegisterInSubevent = (actualEvent, eventChecked) => {
      if(actualEvent.event_start_date != eventChecked.event_start_date) {
        return true;
      }
      if(actualEvent.event_start_date == eventChecked.event_start_date && actualEvent.event_start_hour != eventChecked.event_start_hour){
        return true;
      }
      return false;
    }

    const dateIsAvailableToRegister = event => {
      let currentDate = new Date();
      let startRegisterDate = new Date(event.start_register_date);
      let endRegisterDate = new Date(event.end_register_date);

      //currentDate.setTime(currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000); //Acomodar el offset del horario
      currentDate.setHours(0, 0, 0, 0);
      startRegisterDate.setTime(startRegisterDate.getTime() + startRegisterDate.getTimezoneOffset() * 60 * 1000); //Acomodar el offset del horario
      endRegisterDate.setTime(endRegisterDate.getTime() + endRegisterDate.getTimezoneOffset() * 60 * 1000); //Acomodar el offset del horario

      return currentDate >= startRegisterDate && currentDate <= endRegisterDate;
    };

    const handlerRegister = () => {
      useRouter().push('/');
    };

    const userRoles = getItem('roles');
    userRoles[0].nombre = userRoles[0].nombre
      .normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
      .normalize();

    return {
      router,
      state,
      getEventDetail,
      aimedAt,
      handlerRegister,
      formatDate,
      base_url,
      handleDownloadFile,
      dateIsAvailableToRegister,
      userRoles,
      getDinamicForm,
      handleEnabledEvents,
      dateIsAvailableToRegisterInSubevent,
      removeSeconds
    };
  },
};
</script>

<style scoped lang="sass">
.event-detail
  padding: 32px
  .title
    font-family: 'Nutmeg Bold'
    margin-bottom: 20px
    font-size: 18px

  .subtitle
    color: #404040
    font-family: 'Avenir Heavy'
    font-size: 14px
    font-weight: 700
  .content
    color: #7B868C
    font-family: 'Avenir Roman'
    font-size: 14px
  .files
    color: #E8394D
    font-family: 'Nutmeg Bold'
    font-size: 12px

    .files-icon
      width: 8px
      margin-right: 8px
    &:hover
      cursor: pointer
  .event--card1
    background: #F9F9F9 0% 0% no-repeat padding-box
    border-radius: 20px
    opacity: 1
    .event_image
      border-radius: 20px
      width: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 20px
  .event__description
      background: #FFFFFF
      box-shadow: 0px 3px 6px #00000029
      border-radius: 20px
      opacity: 1
      margin-top: 32px
      padding: 15px 15px
      font-family: 'Avenir Roman'
      color: #7B868C
      p:first-child
        color: #404040
        font-family: 'Avenir Heavy'


  .event--card
    padding: 33px 31px
    background: #FFFFFF
    box-shadow: 0px 3px 6px #00000029
    border-radius: 20px
    opacity: 1
    font-size: 18px
    @media only screen and (max-width: 767px)
      margin-top: 30px
    margin-bottom: 20px
    p
      margin-bottom: 8px
    .files-content
      max-height: 100px !important
      overflow: hidden
  .subevent--card
    border: 1px solid rgb(204, 204, 204)

.event-detail__buttons
  width: 100%
  margin-top: 30px

.btn-register
  background: #E8394D
  color: #fff
  border-radius: 24px
  border: none
  font-size: 14px
  font-family: 'Avenir Heavy'
  margin: 0 auto
  width: 150px
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  gap: 11px

  &:hover
    cursor: pointer

.subevent-row
  display: flex
  flex-wrap: nowrap
  padding: 0
  align-items: center
  @media only screen and (max-width: 769px)
    flex-direction: column-reverse
</style>
